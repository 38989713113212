(function( $ ) {
	'use strict';

	function makePostForm( submitUrl, data ) {

		var form = '';
		form += '<form method="post" action="' + submitUrl + '">';

		$.each( data, function( name, value ) {
			form += '<input type="hidden" name="' + name + '" value="' + value + '">';
		});

		form += '</form>';

		return form;
	}

	function submitPostForm( submitUrl, data ) {
		var $form = $( makePostForm( submitUrl, data ) );

		$( 'body' ).append( $form );

		$form.submit();
	}

	$.fn.tethysPlantTypeahead = function() {

		this.each(function() {

			var $el = $( this ),
				autosubmit = $el.data( 'plantSelectAutosubmit' ),
				autosubmitUrl = $el.data( 'plantSelectSubmitUrl' );

			$el.typeahead({
				hint: false,
				highlight: true,
				minLength: 1
			},
			{
				name: 'plants',
				source: function( query, cb ) {
					$.getJSON('/api/get_members?q=' + encodeURIComponent( query ), function( json ) {

						cb( json );

					}).fail(function() {
						console.log('fejl');
					});
				}
			}).on( 'typeahead:selected', function( e, data ) {

				// Post to the correct url if activated
				if ( autosubmit ) {
					submitPostForm( autosubmitUrl, { id: data.number });
				}

				// Set the value of the id element(s) to the plant number
				$( '.js-signup-id' ).val( data.number );

			});

		});

	};

})( jQuery );
