(function( $ ) {
	'use strict';

	$.fn.scrollToHere = function( options ) {

		// Defaults
		var settings = $.extend({
			'time'    : 1000,
			'easing'  : 'swing',
			'offset'  : 100,
			'delay'   : 200
		}, options);

		var $el = this.eq( 0 ), // Scroll to first match
			offset = $el.offset();

		// Wait until load
		$( window ).on( 'load', function() {

			// We also invoke a delay to stop jaggedness
			window.setTimeout(function() {

				// Scroll to the element
				if (offset) {
					$( 'html, body' ).animate({ scrollTop: ( offset.top - settings.offset) }, settings.time, settings.easing, function() { $el.addClass( 'has-scrolled' ); });
				}

			}, settings.delay );

		});

		return this;
	};

})(jQuery);