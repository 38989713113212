/* global jQuery, FastClick */


// Set Parsley validation locale
window.ParsleyValidator.setLocale( 'da' );

window.ParsleyConfig = {
    excluded: 'input[type=button], input[type=submit], input[type=reset]',
    inputs: 'input, textarea, select, input[type=hidden]',
};

(function( $ ) {
	'use strict';

	$(function() {

		// Attach fastclick to prevent click delays on mobile
		FastClick.attach( document.body );

		$( '.js-fade-tabs' ).tethysFadeTabs();

		$( '.js-header' ).tethysMobileTrigger();

		$( '.js-plant-select' ).tethysPlantTypeahead();

		$( '.js-reveal' ).slideReveal();

		$( '.js-phone-me' ).phoneMe();

		$( '.js-scroll-to-here' ).scrollToHere();

		$( '.js-video-highlight' ).tethysVideoHighlight();

		// License text popup
		$( '.js-license-text' ).magnificPopup({
			type: 'ajax',
			mainClass: 'lightbox mfp-zoom-in',
			removalDelay: 500 //delay removal by X to allow out-animation
		});

		// Print functionality
		$( '.js-print' ).on( 'click', function() {
			window.print();
		});



	});

})( jQuery );

