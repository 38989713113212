(function( $ ) {
	'use strict';

	$.fn.tethysMobileTrigger = function() {

		var menuShown = false,
			mobileClass = 'show-mobile-nav',
			triggerClass = 'js-mobile-trigger',
			$header = $( this ),
			$link  = $( '.' + triggerClass, $header ),
			$body = $( 'body' );

		function showMenu() {
			$link.addClass( 'burger--close' );
			$body.addClass( mobileClass );
			menuShown = true;
		}

		function hideMenu() {
			$link.removeClass( 'burger--close' );
			$body.removeClass( mobileClass );
			menuShown = false;
		}

		function toggleMenu() {
			if ( menuShown ) {
				hideMenu();
			} else {
				showMenu();
			}
		}

		// Close on background click
		$(document).on('click', function(event) {
			if (!$(event.target).closest( $header ).length) {
				hideMenu();
			}
		});

		// Toggle when clicking burger icon
		$link.on( 'click', function() {
			toggleMenu();

			return false;
		});

	};

})( jQuery );