(function( $ ) {
	'use strict';

	$.fn.slideReveal = function() {

		var settings = {
			slideSpeed: 400
		};

		return this.each(function() {

			var $el = $( this ),
				revealElSelector = $el.data( 'revealEl' ),
				initText         = $el.text(),
				hideText         = $el.data( 'revealHideText' ) || initText,
				$revealEl        = $( revealElSelector ),
				visible          = $revealEl.is( ':visible' );

			if ( !$revealEl.length ){
				return false;
			}

			$el.on( 'click', function() {

				$revealEl.stop(); // Stop any ongoing animations

				if ( visible ) {
					$revealEl.slideUp( settings.slideSpeed );
					$el.text( initText );
				} else {
					$revealEl.slideDown( settings.slideSpeed );
					$el.text( hideText );
				}

				visible = !visible;

				return false;

			});

		});

	};

})( jQuery );