(function( $ ) {
	'use strict';

	$.fn.phoneMe = function() {

		var settings = {
			postUrl: '/callCustomer/'
		};

		return this.each(function() {

			var $el = $( this ),
				number   = $el.data( 'phoneNumber' ),
				doneText = $el.data( 'phoneDoneText' ),
				failText = $el.data( 'phoneFailText' );

			// We need a phone number to do this
			if ( !number.length ){
				return false;
			}

			$el.on( 'click', function() {

				// Prevent multiple clicks
				if ( $el.hasClass( 'is-loaded' ) || $el.hasClass( 'is-loading' ) ) {
					return false;
				}

				$el.addClass( 'is-loading' );

				var promise = $.post( settings.postUrl, { 'number': number } ).promise();

				promise.done(function() {
					$el.addClass( 'is-loaded' ).addClass( 'btn--green' ).text( doneText );
					$el.siblings( '.js-phone-me-confirm' ).show();
				});

				promise.fail(function() {
					$el.addClass( 'is-failed' ).text( failText );
				});

				// Always remove is-loading
				promise.always(function() {
					$el.removeClass( 'is-loading' );
				});


				return false;

			});

		});

	};

})( jQuery );