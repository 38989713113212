(function( $ ) {
	'use strict';

	$.fn.tethysFadeTabs = function() {

		return this.each(function() {

			var $el = $( this ),
				$links = $( 'a', $el );

			$links.on( 'click', function( e ) {
				e.preventDefault();

				var $this           = $( this ),
					$li             = $this.closest( 'li' ),
					hash            = this.hash,
					$target         = $( hash ),
					$targetSiblings = $target.siblings();

				$targetSiblings.addClass( 'is-fading-out' );

				// Publish the change
				$el.trigger( 'tabs:change', [ $li, $target ] );

				// Fade the target in after 500ms
				window.setTimeout(function() {
					$targetSiblings.addClass( 'is-hidden' ).removeClass( 'is-fading-out' );
					$target.removeClass( 'is-hidden' );
				}, 500);

				// Toggle selected class
				$li.addClass( 'selected' )
					.siblings()
						.removeClass( 'selected' );

			});

		});

	};

})( jQuery );