/* global Waypoint, console */

(function( $ ) {
	'use strict';

	function getSelectedVideo( $rootEl ) {
		return $rootEl.find( '.js-video-highlight-video:visible' )[ 0 ];
	}

	function playSelectedVideo( $rootEl ) {
		var video = getSelectedVideo( $rootEl );
		startVideo( video );
	}

	function stopSelectedVideo( $rootEl ) {
		var video = getSelectedVideo( $rootEl );
		stopVideo( video );
	}

	function stopAllVideos( $rootEl ) {
		$rootEl.find( '.js-video-highlight-video' ).each(function( i, el ) {
			stopVideo( el );
		});
	}

	function startVideo( video ) {
		if ( video.play && video.paused ) {
			video.play();
			console.log('Starting video: ', video );
		}

	}

	function stopVideo( video ) {
		if ( video.pause && !video.paused ) {
			video.pause();
			console.log('Stopping video: ', video );
		}
	}

	$.fn.tethysVideoHighlight = function() {

		return this.each(function() {

			var $el = $( this ),
				$tabs = $el.find( '.js-fade-tabs' );

			// When the tab changes, stop teh playing videos and start the current one
			$tabs.on( 'tabs:change', function( undefined, $tab, $element ) {
				stopAllVideos( $el );
				startVideo( $element.find( '.js-video-highlight-video' )[ 0 ] );
			});

			// Trigger events on scroll into view
			var waypoint = new Waypoint.Inview({
				element: $el[0],
				enter: function() {
					playSelectedVideo( $el );
				},
				entered: function() {
					//console.log('entered');
				},
				exit: function() {
					//console.log('exit');
				},
				exited: function() {
					stopSelectedVideo( $el );
				}
			});

		});

	};

})( jQuery );
